
.about-body {
    display: flex;
    justify-content: center;
}

.about-text-content {
    padding: 0 1rem;
    width: calc(1080px - 2rem);
}

.email {
    font-weight: bold;
}

.link {
    color: cornflowerblue;
}

.link:visited {
    color: cornflowerblue;
}
